export const initializeContainer = (container) => {
    const ratio = container.getAttribute("data-ratio");
    const widthToSwitchToColumnAttr = container.getAttribute("data-widthToSwitchToColumn");
    const leftElement = container.querySelector(".ratio-container__left");
    const rightElement = container.querySelector(".ratio-container__right");
    if (!ratio || !widthToSwitchToColumnAttr || !leftElement || !rightElement) {
        console.error("Missing or invalid attributes/elements for ratio-container:", container);
        return undefined;
    }
    const widthToSwitchToColumn = parseInt(widthToSwitchToColumnAttr, 10);
    if (isNaN(widthToSwitchToColumn)) {
        console.error("Invalid widthToSwitchToColumn value:", container);
        return undefined;
    }
    return {
        container,
        leftElement,
        rightElement,
        ratio,
        widthToSwitchToColumn,
    };
};
export const getFlexValues = (ratio) => {
    const [leftRatio, rightRatio] = ratio.split(":").map(Number);
    const total = leftRatio + rightRatio;
    return [(leftRatio / total) * 100, (rightRatio / total) * 100];
};
export const updateLayout = (container) => {
    const { container: element, leftElement, rightElement, ratio, widthToSwitchToColumn } = container;
    const [leftFlex, rightFlex] = getFlexValues(ratio);
    const windowWidth = window.innerWidth;
    leftElement.style.flex = `${leftFlex}%`;
    rightElement.style.flex = `${rightFlex}%`;
    if (windowWidth > widthToSwitchToColumn) {
        element.classList.remove("ratio-container__column");
    }
    else {
        element.classList.add("ratio-container__column");
    }
};
export const initializeRatioContainers = () => {
    const containers = document.querySelectorAll(".ratio-container");
    if (containers.length) {
        const ratioContainers = Array.from(containers)
            .map((container) => initializeContainer(container))
            .filter((container) => container !== undefined);
        ratioContainers.forEach(updateLayout);
        window.addEventListener("resize", () => {
            ratioContainers.forEach(updateLayout);
        });
    }
};
if (typeof window !== "undefined") {
    document.addEventListener("DOMContentLoaded", initializeRatioContainers);
}
